import { alphaUrl, ajaxApi } from '@/utils/request'

/** 客户信息 */
export type CustomInfo = {
  /** 详细地址 */
  address: string
  /** 城市代码 */
  cityCode: string
  /** 城市名称 */
  cityName: string
  /** 联系人邮箱 */
  contactEmail: string
  /** 联系人姓名 */
  contactName: string
  /** 联系人电话 */
  contactPhone: string
  /** 联系人的职务 */
  contactPosition: string
  /** 客户名称 */
  custName: string
  /** 客户类型（企业、事业单位、政府机关） */
  custType: string
  /** 行业代码 */
  industryCode: string
  /** 行业名称 */
  industryName: string
  /** 省份代码 */
  provinceCode: string
  /** 省份名称 */
  provinceName: string
  /** 统一社会信用代码 */
  uscCode: string
}
/** 统计信息 */
export type StatisticsInfo = {
  /** 候选人数量 */
  candidateNum: number
  /** 已完成数量 */
  finishedOrderNum: number
  /** 授权中数量 */
  inAuthOrderNum: number
  /** 调查中数量 */
  inCheckOrderNum: number
  /** 阶段性完成数量 */
  inReportOrderNum: number
  /** 部门数量 */
  officeNum: number
  /** 职位数量 */
  positionNum: number
  /** 订单数量 */
  orderNum: number
}
/** 新增客户 */
export const addCustom = (params: CustomInfo) => ajaxApi('post', alphaUrl + '/cust/add', params)

/** 获取客户首页统计信息 */
export const getStatistics = () => ajaxApi('get', alphaUrl + '/cust/statistics') as Promise<StatisticsInfo>
