
import { Component, Prop, Vue } from 'vue-property-decorator'
import XBadge from '@/components/XBadge.vue'

@Component({
  name: 'Block',
  components: { XBadge },
})
export default class extends Vue {
  @Prop({ default: '名称' }) name!: string
  @Prop({ default: 0 }) value!: number
  @Prop({ default: '' }) color!: string
  /** 点击 */
  onClick() {
    this.value && this.$emit('click')
  }
}
