
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'XSvgIcon',
})
export default class extends Vue {
  /** 文本 */
  @Prop({ default: '' }) text!: String
  /** 小圆点颜色 */
  @Prop({ default: '' }) color!: String

  /** 小圆点样式 */
  get dotStyle() {
    return `background-color: ${this.color}`
  }
}
