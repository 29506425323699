
import { Component, Vue } from 'vue-property-decorator'
import { getStatistics, StatisticsInfo } from '@/api/custom'
import { getOrderPage, OrderInfo, getOrderReport } from '@/api/order'
import { getSysParam, ParamInfo } from '@/api/sys'
import XBox from '@/components/XBox.vue'
import ImgDialog from '@/components/ImgDialog.vue'
import MBlock from './MBlock.vue'
import MContract from '../own/MContract.vue'
import { checkResultFilter, parseTime } from '@/utils/filters'
import { OptionModule } from '@/store/modules'
import { downloadByUrl } from '@panstar/common'

@Component({
  name: 'Dashboard',
  components: { XBox, ImgDialog, MBlock, MContract },
  filters: {
    checkResultFilter,
    parseTime,
  },
})
export default class extends Vue {
  /** 统计信息 */
  statisticsInfo = {} as StatisticsInfo
  /** 数据 */
  list: OrderInfo[] = []
  listLoading = true

  /** 职位数据 */
  get jobList() {
    return OptionModule.jobList
  }

  /** 部门数据 */
  get officeList() {
    return OptionModule.officeList
  }

  infoList: ParamInfo[] = []
  qrCodeVisible = false
  imgUrl = ''

  filterHandlerOffice(value: any, row: any) {
    return row.officeId === value
  }

  filterHandler(value: any, row: any, column: any) {
    const property = column.property
    return row[property] === value
  }

  async getStatistics() {
    this.statisticsInfo = await getStatistics()
  }

  async getList() {
    this.listLoading = true
    const { recordList } = await getOrderPage({
      pageNum: 1,
      pageSize: 10,
      status: '07',
    })
    this.list = recordList || []
    this.listLoading = false
  }

  async getSysParam() {
    this.infoList = await getSysParam()
  }

  /** 跳转到订单页面 */
  onClickMore() {
    this.$router.push({ name: 'order' })
  }

  /** 跳转到指定页面 */
  goPage(name: string, params?: any) {
    this.$router.push({ name, params })
  }

  /** 订单详情 */
  onDetail(record: OrderInfo) {
    // console.log('realFeedbackTime', record)
    // record.reportId = 'FILE20230630173725320';
    getOrderReport(record.reportId).then(info => {
      downloadByUrl(info.fileUrl)
    })
  }

  /** 显示二维码弹窗 */
  showQrCode(url: string) {
    this.imgUrl = url
    this.qrCodeVisible = true
  }

  created() {
    this.getStatistics()
    this.getList()
    this.getSysParam()
  }
}
